import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import DynamicHead from '../../components/dynamicHead/dynamicHead';
import Footer from "../../components/footer/footer";
import Iframe from '../../components/iframe/iframe';
import NavBar from '../../components/navBar/navBar';
import ProcessNavBar from '../../components/processNavBar/processNavBar';
import { axiosResourcesAPI } from '../../services/api';
import { getAllProcess, getProcess, getSiteConfig } from '../../services/sendRequest';
import utils, { ClassId, PortalId } from '../../utils';
import "./_bpmnEditor.scss";

type ParamsType = {
  pId: string
}

type LocationState = {
  isUpload: Boolean,
  name: string
}

const BpmnEditor = () => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [alternativeUrls, setAlternativeUrls] = useState<Array<any>>([]);
  const [t, i18n] = useTranslation();
  const [languages, setLanguages] = useState<any>(null);
  const {pId} = useParams<ParamsType>();
  const [processObj, setProcessObj] = useState<any>(null);
  const [lastEditedProcess, setLastEditedProcess] = useState<any>(undefined);
  const [tpCookiesEnabled, setTpCookiesEnabled] = useState<Boolean|undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const isUpload = location.state?.isUpload || (!pId);
  const cookieTesterRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    let mounted = true;
    if (user && i18n.language && (tpCookiesEnabled === true)) {
      if (pId) {
        getProcess(pId).then((process) => {
          if (!process) {
            history.replace('./ErrorPage');
          }
          if (mounted) {
            setProcessObj(process);
          }
        });
      } else if (isUpload) {
        let filters: any = {
          sort: {
            _lastUpdateDate: { order: "desc" },
          },
          query: {
            bool: {
              must: [
                { term: { "author._id": user?.code } },
                { term: { "language.code": i18n.language.substr(0, 2) || "en" } },
                { term: { "isPublished": false } }
              ],
            },
          },
          size: 1
        };
        getAllProcess(filters, {pageIndex: 0, pageSize: 1})
          .then((response) => {
            if (response.result?.total && mounted) {
              setLastEditedProcess(response.result.processes[0]);
            } else if (mounted) {
              setLastEditedProcess(null);
            }
        });
      }
    }
    return () => {
      mounted = false;
    }
  }, [user, i18n.language, pId, isUpload, tpCookiesEnabled])

  useEffect(() => {
    async function fetchData() {
      let siteConfig = await getSiteConfig({
        string: processObj?.description || t("BPMN_EDITOR_TITLE"),
        identifier: 'default'
      });

      if (siteConfig) {
        setTitle(siteConfig.title)
        setDescription(siteConfig.description)
        setImgUrl(utils.getImageUrl(ClassId.portal, PortalId, siteConfig.img._id))
        setAlternativeUrls(await utils.setAlternativeUrls(i18n.language));
        setLanguages(siteConfig.languagesRaw);
      }
    }
    fetchData();
  }, [i18n.language, t, processObj]);

  useEffect(()=>{
    if (!pId && user && languages) {
      if (lastEditedProcess === null ) {
        axiosResourcesAPI(user).post("com.sydle.processbox/process/_create", {
          description: t('PROCESS_DEFAULT_DESCRIPTION'),
          identifier: t('PROCESS_DEFAULT_IDENTIFIER'),
          author: {
            _id: user.code,
            _classId: user._class._id
          },
          language: languages.find(
            (lang) => lang.code === i18n.language.substr(0, 2)
            ),
            isPublished: false
          }
          ).then(response => {
            setProcessObj(response.data);
          });
      } else if (lastEditedProcess) {
        setShowModal(true);
      }
    }
  }, [processObj, pId, user, languages, i18n.language, t, lastEditedProcess]);

  useEffect(() => {
    if (processObj) {
      history.replace({ pathname: `/${location.pathname.split('/')[1]}/${processObj._id}`, state: { isUpload: isUpload} });
    }
  }, [processObj, history, location.pathname, isUpload])

  useEffect(() => {
    const cookieTesterCallback = (event) => {
      if (event.data && typeof event.data === 'string') {
        try {
          const data: any = JSON.parse(event.data);
          if (data.subject === 'cookiesTesterReady') {
            cookieTesterRef?.current?.contentWindow?.postMessage(JSON.stringify({ 'test': 'cookie' }), '*');
          }
          if (data.subject === 'cookiesEnabled') {
            setTpCookiesEnabled(data.body);
          }
        } catch (e) { }
      }
    }

    window.addEventListener('message', cookieTesterCallback);

    return () => {
      window.removeEventListener('message', cookieTesterCallback);
    }
  }, [cookieTesterRef])

  return (
    <>
      <DynamicHead
        title={title}
        description={description}
        url={window.location.origin + window.location.pathname}
        imgUrl={imgUrl}
        alternativeUrlsData={alternativeUrls}
      />
      <Modal show={showModal}>
          <Modal.Header>
              <Modal.Title>{t("EDITOR_EXISTING_DRAFT_TITLE")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p> {t("EDITOR_EXISTING_DRAFT_MESSAGE")} </p>
              <Link to={ {pathname: `/p/${lastEditedProcess?.identifier}-${lastEditedProcess?._id}`}}>
                  <p className="text-center">"{lastEditedProcess?.description}"</p>
              </Link>
              <p>{t("EDITOR_EXISTING_DRAFT_PROMPT")}</p>
          </Modal.Body>
          <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowModal(false);
                  setLastEditedProcess(null);
                }}>
                    {t("EDITOR_EXISTING_DRAFT_NEW")}
                </Button>
                <Button variant="primary" onClick={() => {
                  setProcessObj(lastEditedProcess);
                }}>
                    {t("EDITOR_EXISTING_DRAFT_CONTINUE")}
                </Button>
          </Modal.Footer>
      </Modal>

      <Modal show={tpCookiesEnabled === false}>
        <Modal.Header>
            <Modal.Title>{t("THIRD_PARTY_COOKIES_DISABLED_TITLE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p> {t("THIRD_PARTY_COOKIES_DISABLED_MESSAGE")} </p>
            <p> {t("THIRD_PARTY_COOKIES_DISABLED_INFO")} </p>
        </Modal.Body>
        <Modal.Footer>
              <Button variant="outline-primary" onClick={history.goBack}>
                  {t("THIRD_PARTY_COOKIES_DISABLED_BACK_BUTTON")}
              </Button>
        </Modal.Footer>
      </Modal>

      <div id="bpmn-editor">
        <NavBar type={"Process"} searchURL="/search" hasSearchBox alternativeUrlsData={alternativeUrls} />
        <hr/>
        <Container>
          <ProcessNavBar name={ (processObj?.description === t('PROCESS_DEFAULT_DESCRIPTION') && isUpload) || !processObj ? t('EDITOR_NEW_PROCESS') : processObj.description} editing processId={processObj?._id} isPublished/>
        </Container>
        <hr/>
        <Container className="editor-iframe">
          <Iframe processId={processObj?._id} isUpload={isUpload}/>
          <iframe className="invisible" width="0" height="0" ref={cookieTesterRef} id="test" src={`https://${process.env.REACT_APP_SYDLE_ONE_ENV}.sydle.one/api/1/www/_classId/644018f9015a2807ab837d91/open/67ec3bd70deccb78d64e447e/`} title="Third-party cookies tester"></iframe>
        </Container>
        <Footer />
      </div>

    </>
  );
}

export default BpmnEditor;
